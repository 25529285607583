// 引用axios
import axios from "axios";
// 设置全局格式，告诉服务端/客户端以json格式解析数据
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

// 创建实例
const service = axios.create({
    /**
     你可以理解为地址前缀，比如你请求的地址是/user/getUser
     那么在请求的时候，会变成/dev/user/getUser
     **/
    baseURL: 'https://cera.season.ac',
    /**
     请求限制时间，单位为毫秒，这里设置的是10秒
     当该请求超过了10秒，那么该请求会被中断
     **/
    timeout: 10000
});

export default service;