<template>
  <a-space direction="vertical" size="large">
    <p>自助更新订阅链接</p>
    <p>当您的链接无法链接时，请使用本页面更新您的链接</p>
    <a-input v-model="subscribeLink" :style="{width:'340px'}" placeholder="请输入您的订阅链接" allow-clear />
<!--    <a-space :style="{width:'320px'}" placeholder="更新后的订阅链接" disabled />-->
    <a-space direction="horizon">
      <a-input v-model="updatedLink" :style="{width:'250px'}" placeholder="新的订阅链接" readonly/>
      <a-button :stype="{width:'30px'}"  type="primary" @click=clickCopy(this.subscribeLink)>点击复制</a-button>
    </a-space>
    <a-button type="primary" :loading="loading1" @click=updateBtn long>生成新订阅链接</a-button>
  </a-space>
</template>

<script>
import { ref } from 'vue';
import { updateLink } from "@/api/loginApi";
// import { IconPlus } from '@arco-design/web-vue/es/icon';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  // components: {
  //   IconPlus
  // },
  setup() {
    const size = ref('medium');
    const loading1 = ref(false);
    const handleNotification = () => {
      Notification.info({
        title: 'Title',
        content: 'This is a Notification!',
      })
    }
    return {
      size,
      handleNotification,
      loading1
    }

  },
  mounted() {
  },
  data(){
    return{
      subscribeLink:"",
      updatedLink:"",
    }
  },
  methods: {
    getLink(subscribeLink){
      updateLink(subscribeLink).then(res =>{
        if(res.data.isValid) {
          setTimeout(()=>{
            this.loading1 = !this.loading1;
            this.updatedLink = res.data.updatedLink
            this.$message.success('生成成功')
          },1500)
        }
        else{
          setTimeout(()=>{
            this.loading1 = !this.loading1
            this.$message.error('链接格式非法')
          },500)
        }
      })
    },

    setButtonValid(){
      this.loading1 = !this.loading1
    },

    clickCopy() {
      if(this.updatedLink) {
        this.$copyText(this.updatedLink)
        this.$message.success('复制成功')
      }
      else{
        this.$message.info('请先更新您的链接')
      }
    },
    updateBtn(){
      this.loading1 = !this.loading1
      if(this.subscribeLink){
        // console.log(this.subscribeLink)
        this.getLink(this.subscribeLink)
      }
      else{
        this.handleClick()
      }
    },

    handleClick(){
      this.loading1 = !this.loading1
      this.$message.error('请先输入您的订阅链接')
    }
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.wrapper{
  width: 400px;
  padding: 20px;
  border: 1px solid var(~'--color-border');
  border-radius: 4px;
}
</style>
