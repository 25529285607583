import request from "@/utils/axiosUtils.js";

// 登录
export function updateLink(subscribeLink) {
    const data = {
        "subscribeLink": subscribeLink
    };
    return request({
        url: "/api/updateLink",
        method: "post",
        data: data
    });
}